"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render(_c, _vm) {
  var _vm$props$message$use;
  return _c('div', {
    staticClass: "kiwi-messagelist-message kiwi-messagelist-message--modern",
    class: [_vm.props.m().isRepeat() ? 'kiwi-messagelist-message--authorrepeat' : 'kiwi-messagelist-message--authorfirst', 'kiwi-messagelist-message-' + _vm.props.message.type, _vm.props.message.type_extra ? 'kiwi-messagelist-message-' + _vm.props.message.type + '-' + _vm.props.message.type_extra : '', _vm.props.ml.isMessageHighlight(_vm.props.message) ? 'kiwi-messagelist-message--highlight' : '', _vm.props.ml.isHoveringOverMessage(_vm.props.message) ? 'kiwi-messagelist-message--hover' : '', _vm.props.ml.buffer.last_read && _vm.props.message.time > _vm.props.ml.buffer.last_read ? 'kiwi-messagelist-message--unread' : '', _vm.props.message.nick.toLowerCase() === _vm.props.ml.ourNick.toLowerCase() ? 'kiwi-messagelist-message--own' : '', _vm.props.ml.message_info_open === _vm.props.message ? 'kiwi-messagelist-message--info-open' : '', _vm.props.ml.message_info_open && _vm.props.ml.message_info_open !== _vm.props.message ? 'kiwi-messagelist-message--blur' : '', _vm.props.message.user && _vm.props.m().userMode(_vm.props.message.user) ? 'kiwi-messagelist-message--user-mode-' + _vm.props.m().userMode(_vm.props.message.user) : '', _vm.data.staticClass],
    attrs: {
      "data-message-id": _vm.props.message.id,
      "data-nick": (_vm.props.message.nick || '').toLowerCase(),
      "data-host": (((_vm$props$message$use = _vm.props.message.user) === null || _vm$props$message$use === void 0 ? void 0 : _vm$props$message$use.host) || '').toLowerCase()
    },
    on: {
      "click": function click($event) {
        return _vm.props.ml.onMessageClick($event, _vm.props.message, true);
      },
      "dblclick": function dblclick($event) {
        return _vm.props.ml.onMessageDblClick($event, _vm.props.message);
      }
    }
  }, [_c('div', {
    staticClass: "kiwi-messagelist-modern-left"
  }, [_vm.props.m().displayAvatar(_vm.props.message) ? [_c(_vm.injections.components.UserAvatar, {
    tag: "component",
    attrs: {
      "data-nick": _vm.props.message.nick,
      "user": _vm.props.message.user,
      "network": _vm.props.m().getNetwork(),
      "message": _vm.props.message
    }
  })] : _vm._e()], 2), _c('div', {
    staticClass: "kiwi-messagelist-modern-right"
  }, [_c('div', {
    staticClass: "kiwi-messagelist-top"
  }, [_vm.props.message.nick ? _c('a', {
    class: ['kiwi-messagelist-nick', _vm.props.message.user && _vm.props.m().userMode(_vm.props.message.user) ? 'kiwi-messagelist-nick--mode-' + _vm.props.m().userMode(_vm.props.message.user) : ''],
    style: {
      'color': _vm.props.ml.userColour(_vm.props.message.user)
    },
    attrs: {
      "data-nick": _vm.props.message.nick.toLowerCase()
    },
    on: {
      "mouseover": function mouseover($event) {
        _vm.props.ml.hover_nick = _vm.props.message.nick.toLowerCase();
      },
      "mouseout": function mouseout($event) {
        _vm.props.ml.hover_nick = '';
      }
    }
  }, [_c('span', {
    staticClass: "kiwi-messagelist-nick-prefix"
  }, [_vm._v(_vm._s(_vm.props.message.user ? _vm.props.m().userModePrefix(_vm.props.message.user) : ''))]), _vm._v(_vm._s(_vm.props.message.nick) + " ")]) : _vm._e(), _vm.props.m().showRealName() ? _c('div', {
    staticClass: "kiwi-messagelist-realname",
    on: {
      "click": function click($event) {
        return _vm.props.ml.openUserBox(_vm.props.message.nick);
      },
      "mouseover": function mouseover($event) {
        _vm.props.ml.hover_nick = _vm.props.message.nick.toLowerCase();
      },
      "mouseout": function mouseout($event) {
        _vm.props.ml.hover_nick = '';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.props.message.user.realname) + " ")]) : _vm._e(), _vm.props.m().isMessage(_vm.props.message) && _vm.props.ml.bufferSetting('show_timestamps') ? _c('div', {
    staticClass: "kiwi-messagelist-time",
    attrs: {
      "title": _vm.props.ml.formatTimeFull(_vm.props.message.time)
    }
  }, [_vm._v(" " + _vm._s(_vm.props.ml.formatTime(_vm.props.message.time)) + " ")]) : _vm._e()]), _vm.props.message.bodyTemplate && _vm.props.message.bodyTemplate.$el && _vm.props.ml.isTemplateVue(_vm.props.message.bodyTemplate) ? _c('div', {
    directives: [{
      name: "rawElement",
      rawName: "v-rawElement",
      value: _vm.props.message.bodyTemplate.$el,
      expression: "props.message.bodyTemplate.$el"
    }],
    staticClass: "kiwi-messagelist-body"
  }) : _vm.props.message.bodyTemplate ? _c(_vm.props.message.bodyTemplate, _vm._b({
    tag: "component",
    staticClass: "kiwi-messagelist-body",
    attrs: {
      "buffer": _vm.props.ml.buffer,
      "message": _vm.props.message,
      "idx": _vm.props.idx,
      "ml": _vm.props.ml
    }
  }, 'component', _vm.props.message.bodyTemplateProps, false)) : _c('div', {
    staticClass: "kiwi-messagelist-body",
    domProps: {
      "innerHTML": _vm._s(_vm.props.ml.formatMessage(_vm.props.message))
    }
  }), _vm.props.ml.message_info_open === _vm.props.message ? _c(_vm.injections.components.MessageInfo, {
    tag: "component",
    attrs: {
      "message": _vm.props.message,
      "buffer": _vm.props.ml.buffer
    },
    on: {
      "close": function close($event) {
        return _vm.props.ml.toggleMessageInfo();
      }
    }
  }) : _vm._e(), _vm.props.message.embed.payload && _vm.props.ml.shouldAutoEmbed ? _c('div', [_c(_vm.injections.components.MediaViewer, {
    tag: "component",
    attrs: {
      "url": _vm.props.message.embed.payload,
      "show-pin": true
    },
    on: {
      "close": function close($event) {
        _vm.props.message.embed.payload = '';
      },
      "pin": function pin($event) {
        return _vm.props.ml.openEmbedInPreview(_vm.props.message);
      }
    }
  })], 1) : _vm._e()], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];