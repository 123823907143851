"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-controlinput kiwi-theme-bg",
    class: {
      'kiwi-controlinput--focus': _vm.has_focus,
      'kiwi-controlinput--show-send': _vm.shouldShowSendButton,
      'kiwi-controlinput--show-tools': _vm.shouldShowTools,
      'kiwi-controlinput--show-tools--inline': _vm.shouldShowToolsInline,
      'kiwi-controlinput--selfuser-open': _vm.selfuser_open
    }
  }, [_c('div', {
    staticClass: "kiwi-controlinput-selfuser"
  }, [_c('transition', {
    attrs: {
      "name": "kiwi-selfuser-trans"
    }
  }, [_vm.networkState === 'connected' && _vm.selfuser_open === true ? _c('self-user', {
    attrs: {
      "network": _vm.network
    },
    on: {
      "close": function close($event) {
        _vm.selfuser_open = false;
      }
    }
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "kiwi-controlinput-inner"
  }, [_c('div', {
    staticClass: "avatarctrl",
    on: {
      "click": function click($event) {
        _vm.sidebarState.showUser(_vm.network.currentUser());
      }
    }
  }, [_vm.network && _vm.network.state === 'connected' ? _c('UserAvatar', {
    attrs: {
      "network": _vm.network,
      "user": _vm.network.currentUser(),
      "size": "large"
    }
  }) : _vm._e()], 1), _vm.currentNick ? _c('div', {
    staticClass: "kiwi-controlinput-user",
    on: {
      "click": _vm.toggleSelfUser
    }
  }, [_c('span', {
    staticClass: "kiwi-controlinput-user-nick"
  }, [_vm._v(_vm._s(_vm.currentNick))]), _c('i', {
    staticClass: "fa",
    class: [_vm.selfuser_open ? 'fa-caret-down' : 'fa-caret-up'],
    attrs: {
      "aria-hidden": "true"
    }
  })]) : _vm._e(), _c('form', {
    staticClass: "kiwi-controlinput-form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitForm.apply(null, arguments);
      },
      "click": _vm.maybeHidePlugins
    }
  }, [_vm.autocomplete_open ? _c('auto-complete', {
    ref: "autocomplete",
    attrs: {
      "items": _vm.autocomplete_items,
      "filter": _vm.autocomplete_filter,
      "buffer": _vm.buffer
    },
    on: {
      "temp": _vm.onAutocompleteTemp,
      "selected": _vm.onAutocompleteSelected,
      "cancel": _vm.onAutocompleteCancel
    }
  }) : _vm._e(), _vm.showCommandWarning ? _c('div', {
    staticClass: "kiwi-controlinput-command-warn"
  }, [_c('div', [_c('i', {
    staticClass: "fa fa-exclamation-triangle",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('input_not_command')) + " ")]), _c('div', {
    staticClass: "kiwi-controlinput-command-text"
  }, [_vm._v(" " + _vm._s(_vm.$t('input_send_text')) + " "), _c('input-confirm', {
    attrs: {
      "flip-connotation": true
    },
    on: {
      "ok": function ok($event) {
        return _vm.submitForm();
      },
      "submit": function submit($event) {
        _vm.showCommandWarning = false;
      }
    }
  })], 1)]) : _vm._e(), _vm.buffer.setting('share_typing') ? _c('typing-users-list', {
    attrs: {
      "buffer": _vm.buffer
    }
  }) : _vm._e(), _c('div', {
    staticClass: "kiwi-controlinput-input-wrap"
  }, [_c('irc-input', {
    ref: "input",
    staticClass: "kiwi-controlinput-input",
    attrs: {
      "placeholder": _vm.$t('input_placeholder'),
      "wrap": "off"
    },
    on: {
      "input": _vm.inputUpdate,
      "keydown": function keydown($event) {
        return _vm.inputKeyDown($event);
      },
      "keyup": function keyup($event) {
        return _vm.inputKeyUp($event);
      },
      "click": _vm.closeToolsPlugins,
      "focus": _vm.focusChanged,
      "blur": _vm.focusChanged
    }
  })], 1), _vm.shouldShowSendButton ? _c('div', {
    staticClass: "kiwi-controlinput-send-container kiwi-controlinput-tools"
  }, [_c('button', {
    ref: "sendButton",
    staticClass: "kiwi-controlinput-button kiwi-controlinput-send fa fa-paper-plane",
    attrs: {
      "type": "submit"
    }
  })]) : _vm._e()], 1), _vm.shouldShowTools ? _c('div', {
    ref: "plugins",
    staticClass: "kiwi-controlinput-tools kiwi-controlinput-tools-wrapper"
  }, [!_vm.shouldShowToolsInline ? _c('div', {
    staticClass: "kiwi-controlinput-tools-expand kiwi-controlinput-button",
    class: {
      'kiwi-controlinput-tools-expand--closed': !_vm.showPlugins
    },
    on: {
      "click": function click($event) {
        _vm.showPlugins = !_vm.showPlugins;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-bars",
    attrs: {
      "aria-hidden": "true"
    }
  })]) : _vm._e(), _c('transition', {
    attrs: {
      "name": "kiwi-plugin-ui-trans"
    }
  }, [_vm.showPlugins || _vm.shouldShowToolsInline ? _c('div', {
    staticClass: "kiwi-controlinput-tools-container"
  }, [_vm.shouldShowColorPicker ? _c('div', {
    staticClass: "kiwi-controlinput-button",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onToolClickTextStyle.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-adjust",
    attrs: {
      "aria-hidden": "true"
    }
  })]) : _vm._e(), _vm.shouldShowEmojiPicker ? _c('div', {
    staticClass: "kiwi-controlinput-button",
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onToolClickEmoji.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-smile-o",
    attrs: {
      "aria-hidden": "true"
    }
  })]) : _vm._e(), _vm._l(_vm.pluginUiElements, function (plugin) {
    return _c(plugin.component, _vm._b({
      key: plugin.id,
      tag: "component",
      staticClass: "kiwi-controlinput-button",
      attrs: {
        "plugin-props": {
          buffer: _vm.buffer,
          controlinput: _vm.self
        },
        "network": _vm.network,
        "buffer": _vm.buffer
      }
    }, 'component', plugin.props, false));
  })], 2) : _vm._e()])], 1) : _vm._e()]), _c('div', {
    staticClass: "kiwi-controlinput-active-tool"
  }, [_c(_vm.active_tool, _vm._b({
    tag: "component"
  }, 'component', _vm.active_tool_props, false))], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];